import Behance from './Behance'
import Dribbble from './Dribbble'
import Facebook from './Facebook'
import GCal from './GCal'
import Google from './Google'
import ICal from './ICal'
import Instagram from './Instagram'
import LinkedIn from './LinkedIn'
import Outlook from './Outlook'
import Twitter from './Twitter'
import Vimeo from './Vimeo'
import YouTube from './YouTube'

const SocialIcon = ({ color, type, size }) => {
  const icons = {
    behance: <Behance size={size} />,
    dribbble: <Dribbble size={size} />,
    facebook: <Facebook size={size} color={color} />,
    instagram: <Instagram size={size} />,
    google: <Google size={size} color={color} />,
    linkedin: <LinkedIn size={size} color={color} />,
    twitter: <Twitter size={size} />,
    vimeo: <Vimeo size={size} />,
    youtube: <YouTube size={size} />,
    outlook: <Outlook size={size} />,
    gcal: <GCal size={size} />,
    ical: <ICal size={size} />,
  }
  return icons[type] || 'link'
}

export {
  Behance,
  Dribbble,
  Facebook,
  Instagram,
  LinkedIn,
  Twitter,
  Vimeo,
  YouTube,
  Outlook,
  GCal,
  ICal,
  SocialIcon,
}
