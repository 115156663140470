const Facebook = ({ color = 'black', size = 18 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill={color}
    viewBox="0 0 24 24"
  >
    <path d="M21.8 1H2.2C1.5 1 1 1.5 1 2.2v19.6c0 .6.5 1.2 1.2 1.2h10.5v-8.6H9.9v-3.3h2.8V8.7c0-2.8 1.7-4.4 4.3-4.4 1.2 0 2.2.1 2.6.1v2.9h-1.7c-1.3 0-1.6.6-1.6 1.6V11h3.3l-.5 3.4h-2.8V23h5.5c.6 0 1.2-.5 1.2-1.2V2.2c0-.7-.5-1.2-1.2-1.2z" />
  </svg>
)

export default Facebook
