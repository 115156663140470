import LogoSelect from 'components/general/LogoSelect.tsx'
import { Facebook, Instagram } from 'components/SocialIcon/'
import { getYear } from 'date-fns'
import { useCurrentGroup } from 'lib/GroupContext'
import { memberUrl } from 'lib/urls'
import Link from 'next/link'
import React from 'react'
import { useIntl } from 'react-intl'

import LanguageSwitch from '../LanguageSwitch'

const HelpCenterLinks = () => {
  const { formatMessage, locale } = useIntl()
  const { isMarketplace } = useCurrentGroup()

  const b2cHelpUrl =
    locale === 'fr'
      ? 'https://intercom.help/mentorly/fr/collections/2780229-membre-du-marche'
      : 'https://intercom.help/mentorly/fr/collections/2780229-marketplace-member'
  const b2bHelpUrl = `https://intercom.help/mentorly/${locale}/collections/807636-program-member`

  return (
    <Link href={isMarketplace ? b2cHelpUrl : b2bHelpUrl}>
      <a target="_blank" className="mr-8">
        {formatMessage({ id: 'menu.helpCentre' })}
      </a>
    </Link>
  )
}

const MentorlyLinks = () => {
  const { isMarketplace } = useCurrentGroup()
  const { locale, formatMessage } = useIntl()
  return (
    <div className="flex justify-between">
      <div className="flex flex-col sm:block">
        <Link href={`/${locale}/terms`}>
          <a className="mr-8">{formatMessage({ id: 'menu.terms' })}</a>
        </Link>
        <Link href={`/${locale}/privacy-policy`}>
          <a className="mr-8">{formatMessage({ id: 'menu.privacy' })}</a>
        </Link>
        <HelpCenterLinks />
        <Link href={`/${locale}/about`}>
          <a className="mr-8">{formatMessage({ id: 'menu.about' })}</a>
        </Link>
      </div>
      <div className="flex space-x-2">
        <a
          href={`https://facebook.com/${
            isMarketplace ? 'mentorly' : 'mentorlyenterprise'
          }`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Facebook />
        </a>
        <a
          href="https://instagram.com/mentorlyofficial"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Instagram />
        </a>
      </div>
    </div>
  )
}

const GroupLinks = ({ group }) => {
  const { locale, formatMessage } = useIntl()
  return (
    <div className="flex justify-between">
      <div className="flex flex-col sm:block">
        <Link href={`/${locale}`}>
          <a className="mr-8">{formatMessage({ id: 'menu.home' })}</a>
        </Link>
        <Link href={`/${locale}${memberUrl(group)}`}>
          <a className="mr-8">{formatMessage({ id: 'menu.mentors' })}</a>
        </Link>
        <HelpCenterLinks />
      </div>
      <div className="flex space-x-2">
        {/* social links could go here for b2b clients */}
      </div>
    </div>
  )
}

const Footer = ({ group }) => (
  <footer className="wrapper z-0 bg-lightGray">
    <div className="pt-10 flex flex-col container mx-auto md:px-0 min-h-footer">
      {group?.whiteLabel ? <GroupLinks group={group} /> : <MentorlyLinks />}
      <div className="flex items-center mt-16 space-x-6 h-15">
        <div className="max-w-44 h-full flex items-center">
          <LogoSelect group={group} size="small" />
        </div>
        <div>
          <p>
            Copyright &#169; {getYear(new Date())}{' '}
            {group?.whiteLabel ? group.name : 'Mentorly'}
          </p>
          {(!group || group.languages?.code?.includes('fr')) && (
            <LanguageSwitch />
          )}
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
