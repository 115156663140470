const Outlook = ({ size = 18 }) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 512 512"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0,512) scale(0.1,-0.1)"
      fill="#1270cc"
      stroke="none"
    >
      <path
        d="M2875 4673 c-5 -1 -543 -111 -1195 -243 -652 -132 -1195 -242 -1208
      -245 l-22 -5 0 -1650 c0 -908 3 -1650 8 -1650 4 0 277 -47 607 -105 330 -58
      875 -154 1210 -213 l610 -107 3 1058 c4 1753 -2 3164 -13 3160z m-1101 -1358
      c126 -33 251 -120 324 -226 58 -86 108 -236 132 -396 52 -363 -106 -738 -364
      -863 -214 -103 -470 -55 -647 121 -107 106 -175 275 -200 494 -26 234 39 490
      168 658 139 181 377 266 587 212z"
      />
      <path
        d="M1557 3029 c-165 -38 -259 -216 -258 -487 0 -243 87 -410 237 -457
      93 -29 184 -5 259 70 63 64 95 151 115 321 19 155 -19 341 -93 451 -52 78
      -168 123 -260 102z"
      />
      <path
        d="M2990 3485 l0 -275 750 0 750 0 0 -830 0 -830 -750 0 -750 0 0 -80 0
      -81 783 3 c761 3 783 4 816 23 19 11 43 33 55 48 l21 28 3 1071 c2 1059 2
      1071 -18 1112 -11 23 -33 50 -48 61 -26 20 -45 20 -819 23 l-793 2 0 -275z"
      />
      <path d="M3360 2925 l0 -155 145 0 145 0 0 155 0 155 -145 0 -145 0 0 -155z" />
      <path d="M3720 2925 l0 -156 143 3 142 3 3 153 3 152 -146 0 -145 0 0 -155z" />
      <path d="M4080 2925 l0 -155 140 0 140 0 0 155 0 155 -140 0 -140 0 0 -155z" />
      <path d="M3000 2540 l0 -150 140 0 140 0 0 150 0 150 -140 0 -140 0 0 -150z" />
      <path d="M3360 2540 l0 -150 145 0 145 0 0 150 0 150 -145 0 -145 0 0 -150z" />
      <path d="M3720 2540 l0 -150 145 0 145 0 0 150 0 150 -145 0 -145 0 0 -150z" />
      <path d="M4080 2540 l0 -150 143 0 142 0 0 150 0 150 -142 0 -143 0 0 -150z" />
      <path d="M3000 2178 l0 -153 140 0 140 0 0 153 0 152 -140 0 -140 0 0 -152z" />
      <path d="M3360 2180 l0 -150 145 0 145 0 0 150 0 150 -145 0 -145 0 0 -150z" />
      <path d="M3720 2180 l0 -150 145 0 145 0 0 150 0 150 -145 0 -145 0 0 -150z" />
      <path d="M4080 2180 l0 -150 140 0 140 0 0 150 0 150 -140 0 -140 0 0 -150z" />
      <path d="M3000 1790 l0 -150 140 0 140 0 0 150 0 150 -140 0 -140 0 0 -150z" />
      <path d="M3360 1790 l0 -150 145 0 145 0 0 150 0 150 -145 0 -145 0 0 -150z" />
      <path d="M3720 1790 l0 -150 140 0 140 0 0 150 0 150 -140 0 -140 0 0 -150z" />
    </g>
  </svg>
)

export default Outlook
