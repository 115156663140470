const Dribbble = ({ size = 18 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
  >
    <path
      d="M22.8 9.8c-.1-.7-.4-1.4-.6-2.1-.3-.7-.6-1.3-1-1.9-.4-.6-.8-1.1-1.3-1.6s-1-1-1.6-1.3c-.6-.4-1.2-.7-1.9-1-.7-.3-1.4-.5-2.1-.6-.8-.2-1.6-.3-2.3-.3-.7 0-1.5.1-2.2.2-.7.2-1.4.4-2.1.7-.7.3-1.3.6-1.9 1-.5.4-1.1.8-1.6 1.3s-.9 1-1.3 1.6c-.4.6-.7 1.2-1 1.9-.3.7-.5 1.4-.7 2.1-.1.7-.2 1.5-.2 2.2 0 .7.1 1.5.2 2.2.1.7.4 1.4.6 2.1.3.7.6 1.3 1 1.9.4.6.8 1.1 1.3 1.6s1 .9 1.6 1.3c.6.4 1.2.7 1.9 1 .7.3 1.4.5 2.1.6.7.1 1.5.2 2.2.2.7 0 1.5-.1 2.2-.2.7-.1 1.4-.4 2.1-.6.7-.3 1.3-.6 1.9-1 .6-.4 1.1-.8 1.6-1.3s.9-1 1.3-1.6c.4-.6.7-1.2 1-1.9.3-.7.5-1.4.6-2.1.1-.7.2-1.5.2-2.2.2-.7.1-1.5 0-2.2zM12 2.6c2.4 0 4.5.9 6.2 2.3 0 0-1.4 2.1-4.9 3.4-1.6-3-3.4-5.3-3.5-5.5.7-.1 1.4-.2 2.2-.2zm-2.2.3s-.1 0 0 0c-.1 0-.1 0 0 0-.1 0-.1 0 0 0zM8 3.5c.1.2 1.9 2.5 3.5 5.4C7 10.1 3.1 10.1 2.8 10 3.4 7.2 5.4 4.7 8 3.5zM5 18.3c-1.5-1.7-2.4-3.9-2.4-6.3v-.3c.2 0 4.8.1 9.6-1.3.3.5.5 1.1.8 1.6-.1 0-.2.1-.4.1-5 1.6-7.6 6.2-7.6 6.2zm7 3.1c-2.2 0-4.2-.7-5.8-2s-.1-.1-.2-.2l.2.2c0-.1 1.9-3.9 7.4-5.9h.1c1.3 3.4 1.9 6.3 2 7.1-1.2.5-2.4.8-3.7.8zm5.2-1.6c-.1-.6-.6-3.3-1.8-6.7 3-.5 5.7.3 5.9.4-.4 2.6-1.9 4.9-4.1 6.3zm-2.4-8.2c-.1-.2-.1-.3-.2-.5-.2-.5-.4-.9-.6-1.4C17.7 8.2 19.2 6 19.2 6c1.3 1.6 2.1 3.6 2.1 5.9 0 0-3.2-.7-6.5-.3z"
      id="dribbble"
      className="st0"
    />
  </svg>
)

export default Dribbble
