const Google = ({ color = 'black', size = 18 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill={color}
    viewBox="0 0 24 24"
  >
    <path d="M7.6 10.9v2.6H12c-.6 3.2-5 4.3-7.3 2.2-2.4-1.8-2.3-5.9.2-7.6 1.7-1.4 4.2-1 5.9.2.7-.6 1.3-1.3 1.9-2-1.4-1.2-3.2-2-5.1-1.9C3.7 4.3.1 7.7 0 11.6c-.3 3.2 1.9 6.4 4.8 7.5 3 1.1 6.8.4 8.7-2.3 1.3-1.7 1.5-3.9 1.4-5.9H7.6zM21.8 10.9V8.7h-2.2v2.2h-2.2v2.2h2.2v2.2h2.2v-2.2H24v-2.2h-2.2z" />
  </svg>
)

export default Google
