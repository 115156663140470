const ICal = ({ size = 18 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 526 526"
  >
    <path
      fill="#808285"
      d="M30.7 128.7s-2.8-7 8.4-9.1c11.2-2.1 354.1-54.8 354.1-54.8s8.1-2.5 9.8 7.7c2.2 13.3 57.6 347.8 57.6 347.8L86.9 478.6 30.7 128.7z"
    />
    <path
      fill="#f1f2f2"
      d="M458.9 420.7l1.7-.4S405.2 85.9 403 72.5c-.7-4.6-3-6.9-5-7.5l60.9 355.7z"
    />
    <path fill="#e45652" d="M48.3 97.8l328.1-52 17.5 100.5-329.5 53.4z" />
    <path fill="#fff" d="M63 199l330.9-52.7 39.4 237.5-333.7 51.9z" />
    <path fill="#bcbec0" d="M99.6 435.7l333.7-52.6v18.2L103.8 454z" />
    <path
      fill="#58595b"
      d="M86.9 478.2c.6 8.1 7.5 7 7.5 7s346.8-52.7 358.2-54.8 8.1-10.1 8.1-10.1L86.9 478.2z"
    />
    <path d="M182.1 231h-.3l-36.5 36.3-3.7-13.3 37-36 14.6-1.9L218.6 378l-13.2 2.1L182.1 231zm136.5-35l1.3 14.7-48.7 160L257 373l48.2-161.4-.1-.3-86.5 12.7-1.8-11.3L318.6 196z" />
    <path
      fill="#fff"
      d="M171.6 154.4c8-1.8 9.3-9.2 8.1-16.9l-5.5-37.3 7.3-1.1 5.6 37.9c.8 5.8.8 12.5-3.4 17.1-4.2 4.6-12.3 6.6-18.3 5.5-2.4-.4-4.7-1.6-6.5-3.1-1.2-1-2.2-2.1-3.1-3.3-.3-.4-2.8-4.8-2.8-4.8l5.6-1c2.1 4.4 5.9 7.8 11.1 7.3.7 0 1.3-.1 1.9-.3zm29.5-58.2l4.9 33.5c1.9 12.7 8.3 17.3 15.9 16.1 8.4-1.2 13-7.6 11.1-20.1l-4.9-33.5 7.4-1.1 4.8 33c2.6 17.4-5.6 25.9-17.8 27.7-11.6 1.7-21.3-3.7-23.9-21.2l-4.9-33.4 7.4-1zm47.1-6.9l7.3-1.1 7.4 50.5 27.6-4.5.9 6.1-35 5.6-8.2-56.6z"
    />
  </svg>
)

export default ICal
