import { memberUrl } from 'lib/urls'

export const groupMenu = (group) => {
  const menu = [
    {
      id: 'menu.home',
      path: `/`,
      className: 'hidden xl:block',
    },
    {
      id: 'menu.mentors',
      path: memberUrl(group),
    },
    {
      id: 'menu.schedule',
      path: `/schedule`,
    },
  ]

  if (group?.marketplace) {
    menu.push(
      { id: 'menu.pricing', path: `/pricing` },
      { id: 'menu.inKindFund', path: `/inkind-fund` },
      {
        id: 'menu.faq',
        path:
          'https://intercom.help/mentorly/en/collections/2780229-marketplace-member',
        legacy: true,
      },
      { id: 'menu.about', path: `/about` }
    )
  } else {
    menu.push({
      id: 'menu.dashboard',
      path: '/personal',
      needAuth: true,
    })
  }

  if (group?.url) {
    menu.push({
      id: 'menu.externalUrlLink',
      path: group.url,
      legacy: true,
    })
  }

  return menu
}
