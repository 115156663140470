const LinkedIn = ({ color = 'black', size = 18 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    fill={color}
    viewBox="0 0 24 24"
  >
    <path d="M21.4 1H2.7C1.7 1 1 1.7 1 2.6v18.9c0 .9.7 1.5 1.7 1.5h18.7c.9 0 1.6-.7 1.6-1.6V2.6c0-.9-.7-1.6-1.6-1.6zM4.3 19.8V9.3h3.3v10.5H4.3zm1.6-12c-1.1 0-2-.8-2-1.9 0-1 .8-1.9 1.9-1.9 1 0 1.9.8 1.9 1.9S7 7.8 5.9 7.8zm13.8 12h-3.3v-5.2c0-1.2 0-2.8-1.6-2.8s-1.9 1.3-1.9 2.8v5.2H9.6V9.3h3.1v1.5c.5-.8 1.5-1.7 3.1-1.7 3.3 0 3.9 2.2 3.9 5v5.7z" />
  </svg>
)

export default LinkedIn
