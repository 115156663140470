import Spinner from 'components/feedback/Spinner'
import Footer from 'components/Footer/Footer'
import Header from 'components/Header/Header'
import { useCurrentGroup } from 'lib/GroupContext'
import { groupMenu } from 'lib/groupMenu'
import Head from 'next/head'
import { useIntl } from 'react-intl'

const groupFonts = (group) => {
  const stylesBoldItalic = 'ital,wght@0,400;0,700;0,900;1,400;1,700;1,900'

  const { fontName, titleFontName } = group.styles

  if (!fontName && !titleFontName) return false

  const fontList = [fontName, titleFontName]
    .filter(Boolean)
    .map((font) => `family=${font.replace(' ', '+')}:${stylesBoldItalic}`)

  return fontList.join('&')
}

const MetaHeader = ({ group }) => {
  const { locale, formatMessage } = useIntl()
  const {
    title,
    name,
    whiteLabel,
    backgroundImages,
    subtitle,
    logoImage,
    marketplace,
  } = group

  const description = marketplace
    ? formatMessage({ id: 'seo.b2cDescription' })
    : whiteLabel
    ? formatMessage(
        { id: 'seo.whiteLabelDescription' },
        { program: title, organisation: name, mission: subtitle }
      )
    : formatMessage({ id: 'seo.greyLabelDescription' }, { program: title })

  return (
    <>
      <Head>
        <title>{`${title} | ${whiteLabel ? name : 'Mentorly'}`}</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta property="og:locale" content={locale} key="locale" />
        <meta property="og:title" content={title} key="title" />
        <meta
          property="og:description"
          content={description}
          key="description"
        />
        <meta
          property="og:image"
          content={backgroundImages[0]?.imageUrl || logoImage?.imageUrl}
          key="image"
          test="test"
        />
        <meta property="og:type" content="website" key="type" />
        <meta
          property="twitter:card"
          name="twitter:card"
          content="summary"
          key="twitterCard"
        />
        <meta
          property="twitter:creator"
          name="twitter:creator"
          content={
            whiteLabel ? `@${group.name.replace(' ', '')}` : '@infomentorly'
          }
          key="twitterHandle"
        />
        <meta
          property="twitter:title"
          name="twitter:title"
          content={title}
          key="twitterTitle"
        />
        <meta
          property="twitter:description"
          name="twitter:description"
          content={description}
          key="twitterDescription"
        />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          rel="stylesheet"
          href={`https://fonts.googleapis.com/css2?${groupFonts(
            group
          )}&display=swap`}
        />
      </Head>
    </>
  )
}

const Loading = () => {
  return (
    <div className="h-screen flex justify-center items-center">
      <Spinner />
    </div>
  )
}

const NotFound = () => {
  return (
    <div className="h-screen flex justify-center items-center">
      <h1 className="text-4xl font-black">Not found</h1>
    </div>
  )
}

const Layout = ({ children }) => {
  const { currentGroup, loading } = useCurrentGroup()

  if (loading) {
    return <Loading />
  }

  if (!currentGroup) {
    return <NotFound />
  }

  return (
    <>
      <MetaHeader group={currentGroup} />
      <div className="flex flex-col min-h-screen">
        <Header group={currentGroup} data={groupMenu(currentGroup)} />
        <div
          style={{ fontFamily: currentGroup?.styles.fontName || 'sans-serif' }}
          className="flex flex-1 z-0"
        >
          {children}
        </div>
        <Footer group={currentGroup} />
      </div>
    </>
  )
}

export default Layout
