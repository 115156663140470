const Vimeo = ({ size = 18 }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
  >
    <path d="M23 5.6c-.7-6.1-9.5-2.6-9.5 1.8.9-.2 2.6-.5 2.9.4.4 2.9-1.6 5.3-3.4 6.7C9.6 12.7 12.2 4 8.5 2.6c-1.9-.7-3.5 1-5 2.2-.9.7-2.5 2-2.5 2.9 0 .3.1.5.3.6.7 1.2 1.4-.8 2.7-.4 2.9 2.4 2 13 6.3 13.7 1.7.3 3.8-1.3 5-2.2 1.6-1.4 3.4-3.7 4.7-5.6 0-.1 3.4-4.5 3-8.2z" />
  </svg>
)

export default Vimeo
